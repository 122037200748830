 /* * {
  padding: 0px;
  margin: 0px;
}
body {
  color: rgba(0, 0, 0, 0.5);
  font-family: "Times New Roman", Times, serif;
}
.first-sec {
  width: 100%;
  position: relative;
  height: 400px;
  text-align: center;
  margin-bottom: 10px;
}
.first-sec::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  color: black;
  height: 100%;
  width: 100%;
  background-color: red ;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: -1;
  opacity: 0.7;
}
.title {
  font-size: 35px;
  width: 768px;
  height: 115px;
  text-align: center;
  transform: translate3d(34%, 118%, 0px);
  color: black;
  font-weight: bolder;
}
.second-sec {
  height: 250vh;
  background-color: white;
}
.para {
  line-height: 17px;
  word-spacing: 3px;
  box-shadow: 2px 2px 5px black;
  overflow: hidden;
  padding: 10px;
  padding-bottom: 20px;
  height: 250vh;
  width: 60vw;
  border-radius: 0;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.7);
  transform: translateX(200px);
   margin: 1px auto; 
}
.para > p {
  padding: 5px;
  font-size: 12px;
  color: black;
}
.para > h3 {
  color: black;
}
li {
  margin-left: 40px;
  color: black;
  font-size: 12px;
}
@media screen and (max-width: 1000px) {
  .first-sec {
    height: 130px;
  }
  .title {
    font-size: 18px;
    height: 100%;
    width: 70%;
    transform: translate3d(30%, 20%, 0px);
  }
  .para {
    width: 100vw;
    height: 300vh;
    border-radius: 0;
    transform: translateX(0);
  }
  .para > p {
    padding: 3px;
    font-size: 10px;
  }
  .para > h3 {
    font-size: 12px;
  }
  li {
    font-size: 10px;
  }
  .second-sec {
    height: 300vh;
  }
}  */















* {
  padding: 0px;
  margin: 0px;
}

body {
  color: rgba(0, 0, 0, 0.5);
  font-family: "Times New Roman", Times, serif;
}

/* .first-sec {
  width: 100%;
  position: relative;
  height: 400px;
  text-align: center;
  margin-bottom: 10px;
} */


.first-sec {
  width: 100%;
  position: relative;
  text-align: center;
  background-color: red; /* Apply background color directly */
  color: black; /* Set text color */
}

/* .first-sec::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  color: black;
  height: 100%;
  width: 100%;
  background-color: red;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: -1;
  opacity: 0.7;
} */

/* .title {
  font-size: 35px;
  width: 768px;
  height: 115px;
  text-align: center;
  transform: translate3d(34%, 118%, 0px);
  color: black;
  font-weight: bolder;
} */

.title {
  font-size: 35px;
  /* width: 768px; */
  width: 100%; /* Adjust width */

  /* height: 100px; */
  height: 100px;
  text-align: center;


  top: 50%; /* Position from the top */
  left: 50%; /* Position from the left */
  /* transform: translate3d(4%, 118%, 0px); */
  /* transform: translate(-50%, -50%);  */

  color: black;
  font-weight: bolder;
}


.second-sec {
  height: 80vh; 
  background-color: white;
  overflow-y: scroll; 
}

.para {
  line-height: 17px;
  word-spacing: 3px;
  box-shadow: 2px 2px 5px black;
  padding: 10px;
  /* padding-bottom: 20px; */
  width: 60vw;
  border-radius: 0;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.7);
  margin: 1px auto;
}

.para > p {
  padding: 5px;
  font-size: 14px;
  color: black;
}

.para > h3 {
  color: black;
}

/* .para > .li {
  margin-left: 40px;
  color: black;
  font-size: 14px;
}

li {
  margin-left: 40px;
  color: black;
  font-size: 14px;
} */

/* 
ul {
  list-style-type: none; 
  padding: 0; 
}

li {
  margin-bottom: 10px; 
}

li a {
  text-decoration: none; 
  color: inherit; 
} */

/* li a:hover {
  background-color: #f0f0f0; 
} */


/* 
@media screen and (max-width: 1000px) {
  .first-sec {
    height: 130px;
  }

  .title {
    font-size: 18px;
    height: 100%;
    width: 70%;
    transform: translate3d(30%, 20%, 0px);
  }

  .para {
    width: 90vw;
    height: auto; 
    border-radius: 0;
    transform: translateX(0);
  }

  .para > p {
    padding: 3px;
    font-size: 10px;
  }

  .para > h3 {
    font-size: 12px;
  }

  li {
    font-size: 10px;
  }

  .second-sec {
    height: auto; 
  }
} */

















/* 

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  margin: 0;
  overflow: auto; 
}

body {
  color: rgba(0, 0, 0, 0.8);
  font-family: "Times New Roman", Times, serif;
}

.privacy-container {
  min-height: 100vh; 
}

.first-sec {
  width: 100%;
  position: relative;
  height: 400px;
  text-align: center;
  margin-bottom: 10px;
}

.first-sec::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: red;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: -1;
  opacity: 0.7;
}

.title {
  font-size: 35px;
  width: 768px;
  height: 115px;
  text-align: center;
  transform: translate3d(34%, 118%, 0px);
  color: black;
  font-weight: bolder;
}

.second-sec {
  padding: 20px;
  background-color: white;
}

.para {
  line-height: 1.5;
  word-spacing: 3px;
  box-shadow: 2px 2px 5px black;
  padding: 20px;
  width: 80%;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.7);
}

.para > p {
  padding: 10px 0;
  font-size: 14px;
  color: black;
}

.para > h3 {
  color: black;
  margin-top: 20px;
}

li {
  margin-left: 40px;
  color: black;
  font-size: 14px;
}

@media screen and (max-width: 1000px) {
  .first-sec {
    height: 130px;
  }
  .title {
    font-size: 18px;
    height: 100%;
    width: 70%;
    transform: translate3d(30%, 20%, 0px);
  }
  .para {
    width: 100%;
    padding: 10px;
  }
  .para > p {
    padding: 3px;
    font-size: 12px;
  }
  .para > h3 {
    font-size: 12px;
  }
  li {
    font-size: 12px;
  }
} */
