 .container {
    text-align: center;
    padding: 20px;
    color: white;
  }
  
  #overlay-container .overlay .header {
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  #overlay-container .overlay .paragraph {
    font-size: 18px;
    margin-bottom: 50px;
  }
  
   /* .input {
    padding: 50px;
    margin-bottom: 50px;
  }  */

   #overlay-container .overlay .input {
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 400px;
    font-size: 18px;
    color: #000000;
  }
  
  #overlay-container .overlay .button {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #4285f4;
    color: white;
    margin-top: 20px;
  } 
  
   #overlay-container .overlay .verification-input {
    padding: 10px;
    margin-top: 50px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 400px;
    font-size: 18px;
    color: #000000;

  }
   

  

  .confirm-deletion-container { 
     text-align: center;
    padding: 20px;
    color: rgb(209, 0, 0); 

    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #11328f;
  }
  
  .delete-button,
  .cancel-button {
    padding: 10px 20px;
    font-size: 16px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .delete-button {
    background-color: #f44336;
    color: white;
  }
  
  .cancel-button {
    background-color: #4285f4;
    color: white;
  }
  

  
#overlay-container .overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    z-index: 1;
}

#overlay-container .overlay h2 {
    font-size: 42px;
    color: rgb(255, 255, 255);
    margin-bottom: 20px;
}

#overlay-container .overlay p {
    font-size: 24px;
    color: white;
    margin-bottom: 10px;
} 

#overlay-container .overlay .signin-button {
    font-size: 22px;
    padding: 10px 20px;
    margin: 20px;
    background-color: #4285f4;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

#overlay-container .signin-button:hover {
    background-color: #357ae8;
}

#overlay-container .buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#overlay-container .google-signin {
    margin-top: 20px;
    align-items: center;
    color: white; 
}

/* #overlay-container .google-signin button {
    font-size: 22px;
    padding: 10px 30px;
    align-items: center;
    color: rgb(0, 0, 0); 

}  */


  .header {
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .paragraph {
    font-size: 18px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    max-width: 600px; /* Adjust as needed */
  }
  
/* .input {
    width: 100%;
    max-width: 400px;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 10px;
    color: #11328f;
  }
   */
  /* .button {
    padding: 10px 20px;
    font-size: 16px;
    margin-top: 10px;
  } */
  
  /* .verification-input {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
  } */
  
  #recaptcha-container {
    margin-top: 20px;
  }
  