
html, body {
    height: 100%;
    margin: 0;
    overflow: hidden;
} 

.home-page {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #11328f;
} 

.diagonal-shape {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-top: 350px solid rgb(226, 215, 215); 
    border-right: 350px solid transparent; 
}

.fullscreen-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.top-left-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 170px; 
    height: auto; 
    z-index: 1; 
}



.confirm-deletion-container {
    /* text-align: center;
    padding: 20px;
    color: rgb(209, 0, 0); */

    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #11328f;
  }
  
  .delete-button,
  .cancel-button {
    padding: 10px 20px;
    font-size: 16px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .delete-button {
    background-color: #f44336;
    color: white;
  }
  
  .cancel-button {
    background-color: #4285f4;
    color: white;
  }
  

  
#overlay-container .overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    z-index: 1;
}

#overlay-container .overlay h2 {
    font-size: 42px;
    color: rgb(255, 255, 255);
    margin-bottom: 20px;
}

#overlay-container .overlay p {
    font-size: 24px;
    color: white;
    margin-bottom: 10px;
} 

#overlay-container .signin-button {
    font-size: 22px;
    padding: 10px 20px;
    margin: 20px;
    background-color: #4285f4;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

#overlay-container .signin-button:hover {
    background-color: #357ae8;
}

#overlay-container .buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#overlay-container .google-signin {
    margin-top: 20px;
    align-items: center;
    color: white; /* Add this line to ensure text color is applied */

}

#overlay-container .google-signin button {
    font-size: 22px;
    padding: 10px 30px;
    align-items: center;
    color: rgb(0, 0, 0); /* Add this line to ensure text color is applied */

}
